import { useIonRouter } from '@ionic/react';
import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { DestinationProvider } from '../context/DestinationContext';
import { ImageProvider } from '../context/ImageContext';
import { MessageProvider } from '../context/MessageContext';
import { PathProvider } from '../context/PathContext';
import { RouteContext } from '../context/RouteContext';
import { UserContext } from '../context/UserContext';
import { LoadingRoute } from './LoadingRoute';
import { Login } from './Login';

import { SplashScreen } from '@capacitor/splash-screen';

export const Loading = ({
  privateRoute = false,
  component: Component = Login,
  prompt: LoginPrompt = Login,
  ...restProps
}: any) => {
  const { loading: userLoading, loggedIn, user } = useContext(UserContext);
  const { loading: routesLoading } = useContext(RouteContext);
  const { i18n } = useTranslation();
  const router = useIonRouter();
  const initialLoadDone = useRef(false);

  useEffect(() => {
    if (!(userLoading || routesLoading)) {
      i18n.changeLanguage(user?.language);
    }
  }, [i18n, routesLoading, user?.language, userLoading]);

  useEffect(() => {
    if (privateRoute) {
      if (
        (!userLoading && loggedIn && !routesLoading) ||
        (!userLoading && !loggedIn)
      ) {
        setTimeout(SplashScreen.hide, 1000);
      }
    } else {
      if (!userLoading) {
        setTimeout(SplashScreen.hide, 1000);
      }
    }
  }, [loggedIn, userLoading, routesLoading, privateRoute]);

  useEffect(() => {
    return () => {
      SplashScreen.hide();
    };
  }, []);

  // Only handle auth redirects on initial load or when auth state changes
  useEffect(() => {
    if (privateRoute && !userLoading && !routesLoading) {
      if (!loggedIn && !initialLoadDone.current) {
        const currentPath = window.location.pathname;
        if (currentPath !== '/login') {
          localStorage.setItem('redirectUrl', currentPath);
          router.push('/login');
        }
      }
      initialLoadDone.current = true;
    }
  }, [userLoading, loggedIn, routesLoading, privateRoute, router]);

  const renderComponent = () => {
    // Don't render anything while initial loading
    if (!initialLoadDone.current && (userLoading || routesLoading)) {
      return null;
    }

    if (!privateRoute) {
      return <Component {...restProps} />;
    }

    // For private routes, only render if logged in
    if (loggedIn) {
      return (
        <DestinationProvider>
          <ImageProvider>
            <PathProvider>
              <MessageProvider>
                <LoadingRoute component={Component} {...restProps} />
              </MessageProvider>
            </PathProvider>
          </ImageProvider>
        </DestinationProvider>
      );
    }

    // Return null while redirecting to login
    return null;
  };

  return <>{renderComponent()}</>;
};
