import { Preferences } from '@capacitor/preferences';

const AUTH_STATE_KEY = 'auth_state';

interface AuthState {
  uid?: string;
  email?: string | null;
}

export const saveAuthState = async (state: AuthState) => {
  try {
    await Preferences.set({
      key: AUTH_STATE_KEY,
      value: JSON.stringify(state),
    });
  } catch (error) {
    console.error('Error saving auth state:', error);
    throw error;
  }
};

export const getAuthState = async (): Promise<AuthState | null> => {
  try {
    const { value } = await Preferences.get({ key: AUTH_STATE_KEY });
    return value ? JSON.parse(value) : null;
  } catch (error) {
    console.error('Error getting auth state:', error);
    return null;
  }
};

export const clearAuthState = async () => {
  try {
    await Preferences.remove({ key: AUTH_STATE_KEY });
    // Clear any other auth-related items from storage
    localStorage.removeItem('token');
    localStorage.removeItem('redirectUrl');

    // Clear any other auth-related Preferences
    const authRelatedKeys = [
      'auth_method',
      'auth_link',
      'token',
      'user',
      'route',
    ];

    await Promise.all(
      authRelatedKeys.map((key) =>
        Preferences.remove({ key }).catch((e) =>
          console.warn(`Error removing ${key}:`, e)
        )
      )
    );
  } catch (error) {
    console.error('Error clearing auth state:', error);
    throw error;
  }
};
