import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Initialize Firebase - move this into a separate file
const app = initializeApp({
  apiKey: 'AIzaSyDF77wy8VM5U8_LB_48i5E_Q-ySxiPObVE',
  authDomain: 'camino-for-good-co.firebaseapp.com',
  databaseURL: 'https://camino-for-good-co.firebaseio.com',
  projectId: 'camino-for-good-co',
  storageBucket: 'camino-for-good-co.appspot.com',
  messagingSenderId: '594573542603',
  appId: '1:594573542603:web:02b7e0c96a92a70ec7074e',
  measurementId: 'G-S4BR1YTEEN',
});

export const auth = getAuth(app);
