import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Preferences } from '@capacitor/preferences';
import { IonIcon, IonImg, isPlatform, useIonRouter } from '@ionic/react';
import { auth } from '../firebase';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  browserPopupRedirectResolver,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  linkWithRedirect,
  onAuthStateChanged,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth';
import { mailOutline as emailIcon } from 'ionicons/icons';

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import logoApple from '../assets/apple.png';
import logoFacebook from '../assets/facebook.png';
import logoGoogle from '../assets/google.png';
import { useLocalStorage } from '../hooks/useLocalStorage';
import {
  clearAuthState,
  getAuthState,
  saveAuthState,
} from '../util/authPersistence';
import constants from '../util/constants';
import { UserContext } from './UserContext';
import { LoadingOverlay } from '../components/LoadingOverlay';

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const AppleProvider = new OAuthProvider('apple.com');
const GoogleProvider = new GoogleAuthProvider();
const FacebookProvider = new FacebookAuthProvider();

AppleProvider.addScope('email');
AppleProvider.addScope('name');

let userLoggedIn = false;

export type ProviderListItem = {
  appProvider?: string;
  id: string;
  logo: any;
  provider?: any;
  nativeProvider?: any;
  credentialApp?: any;
};

const filteredProviderList = () => {
  const providerList = [
    {
      id: 'google',
      appProvider: 'google.com',
      provider: GoogleProvider,
      nativeProvider: FirebaseAuthentication.signInWithGoogle,
      credentialApp: GoogleAuthProvider,
      logo: <IonImg src={logoGoogle} />,
    },
    {
      id: 'facebook',
      appProvider: 'facebook.com',
      provider: FacebookProvider,
      nativeProvider: FirebaseAuthentication.signInWithFacebook,
      credentialApp: FacebookAuthProvider,
      logo: <IonImg src={logoFacebook} />,
    },
    {
      id: 'iOS',
      appProvider: 'apple.com',
      provider: AppleProvider,
      nativeProvider: FirebaseAuthentication.signInWithApple,
      credentialApp: OAuthProvider,
      logo: <IonImg src={logoApple} />,
    },
    {
      id: 'camino',
      logo: <IonIcon icon={emailIcon} />,
      nativeProvider: FirebaseAuthentication.signInWithEmailAndPassword,
    },
  ];

  return isPlatform('android')
    ? providerList.filter((provider) => provider.id !== 'iOS')
    : providerList;
};

export const ProviderList = filteredProviderList();

interface LoginBody {
  authToken?: string;
  email: string;
  password: string;
}

interface LoginConsumer {
  error: string;
  forgotPassword(email: string): Promise<void>;
  loading: boolean;
  providerLogin(provider: ProviderListItem): Promise<void>;
  providerList: ProviderListItem[];
  login(body: LoginBody & { email: string }): Promise<void>;
  setShowNativeLoading: (show: boolean) => void;
  signup(body: LoginBody): Promise<void>;
  validate: Function;
}

const verify = async (body: any = {}) => {
  const idToken = await (body?.authToken
    ? body.authToken
    : auth.currentUser?.getIdToken());
  userLoggedIn = auth.currentUser !== null;

  try {
    const { token } = await fetch(`${constants.ENDPOINT}/auth/verify`, {
      body: JSON.stringify({
        ...body,
      }),
      headers: {
        AuthToken: idToken || '',
        'Content-Type': 'application/json',
      },
      method: 'post',
    }).then((res) => res.json());
    userLoggedIn = true;
    return token;
  } catch (e) {
    console.error('### Verify error:', e);
  }
  return null;
};

const linkAccounts = async (onError: Function) => {
  const { value: auth_method } = await Preferences.get({
    key: 'auth_method',
  });
  const { value: shouldPromptToLink } = await Preferences.get({
    key: 'auth_link',
  });

  if (
    !!shouldPromptToLink &&
    window.confirm(
      `We have detected two accounts with the same email address. Would you like to merge your ${capitalize(
        shouldPromptToLink
      )} and ${capitalize(auth_method || '')} accounts?`
    )
  ) {
    await Preferences.set({
      key: 'auth_link',
      value: '',
    });

    try {
      const provider = ProviderList.find((p) => p.id === shouldPromptToLink);

      if (provider && provider.provider) {
        await linkWithRedirect(
          auth.currentUser!,
          provider.provider,
          browserPopupRedirectResolver
        );
        return true;
      } else {
        return false;
      }
    } catch (e) {
      onError(e);
    }
    return false;
  }

  await Preferences.set({
    key: 'auth_link',
    value: '',
  });
};

export const LoginContext = createContext<LoginConsumer>({
  loading: true,
} as LoginConsumer);

export const LoginProvider = ({ children }: any) => {
  const router = useIonRouter();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showNativeLoading, setShowNativeLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Logging in...');

  // @ts-ignore - unused variable
  const [get, _set, remove] = useLocalStorage(); // eslint-disable-line @typescript-eslint/no-unused-vars
  const { setToken } = useContext(UserContext);

  useEffect(() => {
    if (!showNativeLoading) {
      setLoadingMessage('Logging in...');
    }
  }, [showNativeLoading]);

  const validate = useCallback(
    async (body?: Partial<LoginBody>) => {
      try {
        let source = undefined,
          handle = undefined;
        if (
          window.location.pathname.includes('/register/affiliate') ||
          window.location.pathname.includes('/redeem')
        ) {
          const url = window.location.pathname;
          if (url.includes('/register/affiliate')) {
            [source, handle] = url
              .replace('/register/affiliate/', '')
              .split('/');
          } else {
            [handle] = url.replace('/redeem/', '').split('/');
          }
        }

        const token = await verify({ source, handle, ...body });
        if (token) {
          setToken(token);

          // Get stored redirect URL from localStorage
          const redirectUrl = localStorage.getItem('redirectUrl');
          localStorage.removeItem('redirectUrl'); // Clear stored URL
          if (redirectUrl && window.location.pathname !== '/upgrade') {
            // Prevent redirect loops for dashboard routes
            if (
              redirectUrl.startsWith('/dashboard') &&
              window.location.pathname.startsWith('/dashboard')
            ) {
              return;
            }
            router.push(redirectUrl);
          } else if (
            !redirectUrl &&
            window.location.pathname.startsWith('/login')
          ) {
            router.push('/dashboard/home');
          }
        }
        setLoadingMessage('Redirecting...');
      } catch (error) {
        console.error('Validation error:', error);
        setError((error as Error).message);
      }
    },
    [setToken, router]
  );

  const handleAuthStateChanged = useCallback(
    async (user?: any) => {
      try {
        setLoading(true);
        const authUser = auth.currentUser;

        if (userLoggedIn) {
          setShowNativeLoading(false);
          // Check for login attempt flag
          if (localStorage.getItem('loginAttempted')) {
            localStorage.removeItem('loginAttempted'); // Clear the flag
            const redirectUrl = localStorage.getItem('redirectUrl');
            // Always redirect after login attempt
            router.push(redirectUrl || '/dashboard/home');
          }
          return;
        }

        if (authUser?.uid) {
          userLoggedIn = true;
          await saveAuthState({
            uid: authUser.uid,
            email: authUser.email,
          });
          await linkAccounts(setError);
          await validate(user);
        } else {
          // Clear login attempt flag on logout
          localStorage.removeItem('loginAttempted');
          await clearAuthState();
        }
      } catch (error) {
        console.error('Error handling auth state change:', error);
        // Clear login attempt flag on error
        localStorage.removeItem('loginAttempted');
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    },
    [router, validate]
  );

  const redirectCallback = useCallback(async () => {
    try {
      await auth.authStateReady();

      // Check if we have a stored auth state
      const storedAuthState = await getAuthState();
      if (storedAuthState) {
        setLoading(true);
      }

      if (auth.currentUser) {
        setLoading(true);
        await handleAuthStateChanged(auth.currentUser);
      }
    } catch (error: any) {
      console.error('Auth check error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [handleAuthStateChanged]);

  useEffect(() => {
    const initFirebase = async () => {
      let unsubscribe: (() => void) | undefined;

      try {
        // Set persistence first
        await setPersistence(auth, browserSessionPersistence)
          .then(() => console.log('Persistence set'))
          .catch(console.error);

        // Check for stored auth state
        const storedAuthState = await getAuthState();
        if (storedAuthState) {
          setLoading(true);
        }

        // Then wait for auth to be ready
        await auth.authStateReady();

        // Check for redirect result first
        await redirectCallback();

        // Only set up the auth state listener after redirect check is complete
        unsubscribe = onAuthStateChanged(auth, async (user) => {
          try {
            if (user) {
              await handleAuthStateChanged(user);
            } else {
              await clearAuthState();
              setLoading(false);
            }
          } catch (error) {
            console.error('Error in auth state change handler:', error);
            setError((error as Error).message);
            setLoading(false);
          }
        });
      } catch (error) {
        console.error('Error during Firebase initialization:', error);
        setError((error as Error).message);
        setLoading(false);
      }

      return () => {
        unsubscribe?.();
      };
    };

    initFirebase().catch((error) => {
      console.error('Fatal error during Firebase init:', error);
      setError((error as Error).message);
      setLoading(false);
    });
  }, [handleAuthStateChanged, redirectCallback]);

  const login = async (
    body: LoginBody & { email: string; password: string }
  ) => {
    try {
      const { email, password } = body;

      // Update message at start
      setLoadingMessage('Logging in...');

      let authToken: string;

      // Use native flow if available
      if (isPlatform('cordova') || isPlatform('capacitor')) {
        setShowNativeLoading(true);
        await FirebaseAuthentication.signInWithEmailAndPassword({
          email,
          password,
        });
        authToken = (await FirebaseAuthentication.getIdToken()).token;
      } else {
        // Fallback to the web SDK for non-native platforms
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        authToken = await userCredential.user.getIdToken();
      }

      // Update message before verifying credentials
      setLoadingMessage('Syncing account data...');

      // Store/flag the auth method (if needed)
      await Preferences.set({
        key: 'auth_method',
        value: 'camino',
      });

      // Continue with your custom verification flow
      await validate({ ...body, authToken });
    } catch (error: any) {
      setShowNativeLoading(false);
      console.error('Login error:', error);
      // Handle error (update UI, etc.)
      setError(error.message);
    }
  };

  const signup = async (body: LoginBody) => {
    try {
      const { email, password } = body;
      await createUserWithEmailAndPassword(auth, email, password);
      await signInWithEmailAndPassword(auth, email, password);
      await Preferences.set({
        key: 'auth_method',
        value: 'camino',
      });
      await validate(body);
    } catch (error) {
      console.error('Signup error:', error);
      setError((error as any).message);
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      await Preferences.set({
        key: 'auth_method',
        value: 'camino',
      });
      await sendPasswordResetEmail(auth, email, {
        url: `${process.env.REACT_APP_FRONTEND}/login?email=${email}`,
        handleCodeInApp: false,
      });
    } catch (error) {
      console.error('Forgot password error:', error);
      setError((error as any).message);
    }
  };

  const providerLogin = async (provider: ProviderListItem) => {
    try {
      // Set login attempt flag
      localStorage.setItem('loginAttempted', 'true');

      await Preferences.set({
        key: 'auth_method',
        value: provider.id,
      });

      if (isPlatform('cordova') || isPlatform('capacitor')) {
        setShowNativeLoading(true);
        // Native platform flow
        await provider.nativeProvider();
        const authToken = (await FirebaseAuthentication.getIdToken()).token;
        await validate({ authToken });
      } else {
        // Web platform flow - try popup first, fall back to redirect
        setIsPopupOpen(true);

        try {
          const result = await signInWithPopup(
            auth,
            provider.provider,
            browserPopupRedirectResolver
          );
          setLoading(true);
          await handleAuthStateChanged(result.user);
        } catch (popupError: any) {
          // Handle specific popup errors
          if (
            popupError.code === 'auth/popup-blocked' ||
            popupError.code === 'auth/popup-closed-by-user'
          ) {
            setError('Popup was blocked - redirecting...');

            // Fall back to redirect
            await signInWithRedirect(
              auth,
              provider.provider,
              browserPopupRedirectResolver
            );
            return; // Don't clear loading/popup states since we're redirecting
          }

          // Rethrow other errors
          throw popupError;
        }
      }
    } catch (error: any) {
      console.error('Provider login error:', error);
      // Clear login attempt flag on error
      localStorage.removeItem('loginAttempted');
      if (error.code === 'auth/popup-blocked') {
        setError('Please enable popups for this site to login');
      } else if (error.code === 'auth/cancelled-popup-request') {
        setError('Login cancelled');
      } else {
        setError(error.message);
        setShowNativeLoading(false);
      }
    } finally {
      // Only clear states if we're not redirecting
      if (!error || (error as any)?.code !== 'auth/popup-blocked') {
        setIsPopupOpen(false);
        setLoading(false);
      }
    }
  };

  return (
    <LoginContext.Provider
      value={{
        error,
        forgotPassword,
        loading: loading && !isPopupOpen,
        login,
        providerList: ProviderList,
        providerLogin,
        setShowNativeLoading,
        signup,
        validate,
      }}
    >
      {children}
      {showNativeLoading && <LoadingOverlay message={loadingMessage} />}
    </LoginContext.Provider>
  );
};
